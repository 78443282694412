import {
  type IObservableArray,
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx'
import { type StaticModelCollection } from '../firestore-mobx/model'
import { getCatalogsForUser } from '../firestore/Catalog'
import { deleteTARecordFromAppUser, getUsers } from '../firestore/PublicUser'
import {
  SectionState,
  createSection,
  getSectionsBydId,
  getSectionsStreamForInstructor,
  getSectionsStreamForInstructors,
} from '../firestore/Section'
import { getSectionAssignments } from '../firestore/SectionAssignment'
import { getSectionPromotions } from '../firestore/SectionPromotion'
import { getTAInstructorIDs } from '../firestore/UserProfile'
import { redeemPromotions } from '../firestore/UserPromotionRedemption'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { PublicUser } from '../models/PublicUser'
import { Section } from '../models/Section'
import { SectionAssignment } from '../models/SectionAssignment'
import type { SectionPromotion } from '../models/SectionPromotion'
import type { UserPromotion } from '../models/UserPromotion'
import { UserProfileRole } from '../types'
import { Cubit } from './core'
import { captureException } from '@sentry/core'

export class InstructorClassesCubit extends Cubit {
  repository: FirebaseRepository
  userId: string
  role: string

  @observable showCompleted = false

  sections: StaticModelCollection<Section>
  sharedSections: StaticModelCollection<Section>

  promotionsForSection = observable.map<string, SectionPromotion[]>()

  assignmentsForSection = observable.map<
    string,
    StaticModelCollection<SectionAssignment>
  >()

  TAInstructors: StaticModelCollection<PublicUser>
  TAInstructorIds = observable.array<string>([])
  private _sharedSectionIds: IObservableArray<string> | undefined

  instructorUserId?: string
  instructorUser: PublicUser

  constructor(
    repository: FirebaseRepository,
    role: string,
    defaultShowCompleted = false,
    instructorUserId?: string
  ) {
    super()
    makeObservable(this)
    this.instructorUserId = instructorUserId
    this.userId = repository.uid
    this.repository = repository
    this.role = role

    this.sections = Section.emptyCollection(repository)
    this.sharedSections = Section.emptyCollection(repository)
    this.TAInstructors = PublicUser.emptyCollection(repository)
    this.instructorUser = PublicUser.empty(repository)
    this.showCompleted = defaultShowCompleted
  }

  initialize(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const cubit = this
    if (this.role === 'ta') {
      this.addStream(getTAInstructorIDs(this.repository), (instructorIds) => {
        runInAction(() => {
          this.TAInstructorIds.replace(instructorIds)
        })
      })
      // when instructor IDs updates re-init the instructor profile and sections streams
      this.addReaction({
        whenThisChanges: () => this.TAInstructorIds.length,
        thenRunThisCode: () => {
          const sectionsStreamLabel = 'ta-sections-stream'
          const instructorsStreamLabel = 'ta-instructors-stream'
          this.removeStream(sectionsStreamLabel)
          this.removeStream(instructorsStreamLabel)
          this.addStream(
            getSectionsStreamForInstructors(
              this.repository,
              this.TAInstructorIds
            ),
            (sections) => {
              this.sections.replaceModels(sections)
              this.sharedSections.replaceModels([])
              this.addStreamsForSections()
            },
            { name: sectionsStreamLabel }
          )
          cubit.addStream(
            getUsers(cubit.repository, { userIds: cubit.TAInstructorIds }),
            (instructors) => cubit.TAInstructors.replaceModels(instructors)
          )
        },
      })
      return
    }

    this.addStream(
      getSectionsStreamForInstructor(this.repository, {
        instructorUserId: this.instructorUserId,
      }),
      (sections) => {
        this.sections.replaceModels(sections)
        this.startSectionPromotionStreams(sections)
        this.addStreamsForSections()

        const hasUncompletedSections = sections.some(
          (section) => section.data.sectionState !== SectionState.completed
        )

        // When all sections are completed, default to show completed sections.
        if (!hasUncompletedSections) {
          this.showCompleted = true
        }
      }
    )

    this.addStream(
      getCatalogsForUser(this.repository, {
        userId: this.instructorUserId ?? this.userId,
      }),
      (catalogs) => {
        const sharedSectionStreamKey = 'shared-sections-stream'
        const sharedSectionIds = catalogs.flatMap(
          (catalog) => catalog.data.catalogSharedSectionIds
        )
        if (
          this._sharedSectionIds &&
          this.arraysEqual(sharedSectionIds, this._sharedSectionIds)
        ) {
          return
        }
        this._sharedSectionIds = observable.array(sharedSectionIds)
        this.removeStream(sharedSectionStreamKey)
        this.addStream(
          getSectionsBydId(this.repository, sharedSectionIds),
          (sharedSections) => {
            // iterate over shared sections and get assignments stream
            sharedSections.forEach((section) => {
              const sharedSectionStreamKey = `shared-section-assignments-${section.id}`
              if (this.hasStream(sharedSectionStreamKey)) return
              this.addStreamsForSection(section, sharedSectionStreamKey)
            })
            this.sharedSections.replaceModels(sharedSections)
          },
          {
            name: sharedSectionStreamKey,
          }
        )
      }
    )
    // if we are impersonating an instructor, get the instructor's profile
    if (this.instructorUserId) {
      this.addStream(
        getUsers(this.repository, { userIds: [this.instructorUserId] }),
        (instructors) => {
          this.instructorUser.replaceModel(instructors[0])
          return
        },
        { name: 'instructor-profile' }
      )
    }
  }

  addStreamsForSections() {
    this.sections.models.forEach((section) => {
      this.addStreamsForSection(section)
    })
  }

  @action
  addStreamsForSection(section: Section, name?: string) {
    this.assignmentsForSection.set(
      section.id,
      SectionAssignment.emptyCollection(this.repository)
    )
    this.startStreamsForSection(section, 0, name)
  }

  @action
  startStreamsForSection(section: Section, retryCount = 0, name?: string) {
    this.addStream(
      getSectionAssignments(this.repository, { sectionId: section.id }),
      (assignments) => {
        this.assignmentsForSection.get(section.id)?.replaceModels(assignments)
      },
      {
        name: name ?? `section-assignments-${section.id}`,
        disableCaptureException: true,
        onError: (error) => {
          if (retryCount < 5) {
            setTimeout(() => {
              this.startStreamsForSection(section, retryCount + 1, name)
            }, 400 * retryCount)
          } else {
            captureException(error)
          }
        },
      }
    )
  }

  isSharedSection = (section: Section) => {
    return this._sharedSectionIds?.includes(section.id) ?? false
  }

  @action
  toggleShowCompleted() {
    this.showCompleted = !this.showCompleted
  }

  @computed
  get visibleSections() {
    const sectionSort = (a: Section, b: Section) => {
      if (a.data.sectionState === b.data.sectionState) {
        if (!a.data.updatedAt || !b.data.updatedAt) {
          if (a.data.className !== b.data.className) {
            return a.data.className.localeCompare(b.data.className)
          } else {
            return a.data.sectionName.localeCompare(b.data.sectionName)
          }
        }
        return b.data.updatedAt.getTime() - a.data.updatedAt.getTime()
      }
      return a.data.sectionState - b.data.sectionState
    }

    // Order the sections based on dart rules
    const sortedModels = this.sections.models.slice().sort(sectionSort)

    // append shared sections with assignments to the end of the list
    sortedModels.push(...this.sharedSectionsWithAssignments.sort(sectionSort))

    if (this.showCompleted) return sortedModels
    return sortedModels.filter((section) => {
      return section.data.sectionState !== SectionState.completed
    })
  }

  @computed
  get sectionsByInstructor() {
    const sectionsByInstructorId: Record<
      string,
      { instructor: PublicUser; sections: Section[] }
    > = {}
    const instructorMap = Object.fromEntries(
      this.TAInstructors.models.map((instructor) => [instructor.id, instructor])
    )
    for (const instructor of this.TAInstructors.models) {
      sectionsByInstructorId[instructor.id] = { instructor, sections: [] }
    }
    this.visibleSections.forEach((section) => {
      const instructorId = section.data.instructorUserId
      if (!sectionsByInstructorId[instructorId]) {
        const instructor =
          instructorId in instructorMap
            ? instructorMap[instructorId]
            : PublicUser.empty(this.repository)
        sectionsByInstructorId[instructorId] = { instructor, sections: [] }
      }
      sectionsByInstructorId[instructorId].sections.push(section)
    })
    return sectionsByInstructorId
  }

  @computed
  get sectionDataLoading() {
    const waitingForTAData = this.role === 'ta' && this.TAInstructors.isLoading
    return (
      this.sections.isLoading ||
      this.sharedSections.isLoading ||
      waitingForTAData
    )
  }

  @computed
  get sharedSectionsWithAssignments() {
    return this.sharedSections.models.filter((section) => {
      return this.assignmentsForSection.get(section.id)?.models.length
    })
  }

  createSection = (
    className: string,
    sectionName: string,
    instructorUserId?: string
  ) => {
    if (this.role === UserProfileRole.ta && !instructorUserId) {
      throw new Error('instructorUserId is required for TA to create a section')
    }
    return createSection(this.repository, {
      className,
      sectionName,
      userId: instructorUserId ?? this.userId,
    })
  }

  redeemPromotions = (sectionId: string, userPromotions: UserPromotion[]) => {
    return Promise.all(
      userPromotions.map((userPromotion) =>
        redeemPromotions(this.repository, {
          userId: this.repository.breakoutUser!.uid,
          promotionId: userPromotion.data.promotionId,
          userPromotionId: userPromotion.id,
          sectionId,
        })
      )
    )
  }

  retireAsTA(instructorUserId: string) {
    return deleteTARecordFromAppUser(this.repository.firestore, {
      userId: this.userId,
      instructorUserId,
    })
  }

  private arraysEqual(arr1: unknown[], arr2: unknown[]) {
    if (arr1.length !== arr2.length) return false
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false
    }
    return true
  }

  startSectionPromotionStreams(sections: Section[]): void {
    for (const section of sections) {
      this.startSectionPromotionStream(section)
    }
  }

  startSectionPromotionStream(section: Section, retryCount = 0): void {
    const name = `section-promotions-${section.id}`
    if (this.hasStream(name)) return
    this.addStream(
      getSectionPromotions(this.repository, {
        sectionId: section.id,
      }),
      (promotions) => {
        this.promotionsForSection.set(section.id, promotions)
      },
      {
        name: name,
        disableCaptureException: true,
        onError: (error) => {
          if (retryCount < 5) {
            setTimeout(() => {
              this.startSectionPromotionStream(section, retryCount + 1)
            }, 400 * retryCount)
          } else {
            captureException(error)
          }
        },
      }
    )
  }
}
