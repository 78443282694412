type NoTryResult<T, E = Error> = [T | undefined, E | undefined]

function noop(): void {
  return undefined
}

// eslint-disable-next-line import/no-unused-modules
export function noTry<T, E = Error>(
  fn: () => T,
  handleErr: (error: E) => void = noop
): NoTryResult<T, E> {
  const result: NoTryResult<T, E> = [undefined, undefined]
  try {
    result[0] = fn()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    result[1] = err
    handleErr(err)
  }
  return result
}

export async function noTryAsync<T, E = Error>(
  fn: () => Promise<T>,
  handleErr: (error: E) => void = noop
): Promise<NoTryResult<T, E>> {
  const result: NoTryResult<T, E> = [undefined, undefined]
  try {
    result[0] = await fn()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    result[1] = err
    handleErr(err)
  }
  return result
}
