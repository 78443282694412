import type { CollectionReference, Firestore } from 'firebase/firestore'
import {
  collection,
  collectionGroup,
  doc,
  query,
  serverTimestamp,
  where,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  collectionSnapshots,
  convertDocumentSnapshotToModel,
  modelItemStream,
  modelListStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreOrganization, OrganizationInvoiceStatus } from './schema'
import { OrganizationState, schema } from './schema'
import { Organization } from '../../models/Organization'
import { fetchAppUsers } from '../AppUser'
import {
  addDocWithError,
  deleteDocWithError,
  getDocsWithError,
  getDocWithError,
  setDocWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'

const converter: FirestoreDataConverter<FirestoreOrganization> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreOrganization> => {
  return collection(firestore, 'organization').withConverter(converter)
}

export const getOrganizations = (repository: FirebaseRepository) => {
  const ref = getColRef(repository.firestore)
  // -1 is deleted, rest are viewable
  const q = query(ref, where('organizationState', '>=', 0))
  return modelListStream(repository, q, Organization)
}

export const getOrganization = (
  repository: FirebaseRepository,
  { organizationId }: { organizationId: string }
) => {
  const ref = getColRef(repository.firestore)
  const docRef = doc(ref, organizationId)
  return modelItemStream(repository, docRef, Organization)
}

export const createOrganization = async (
  repository: FirebaseRepository,
  {
    institutionId: optionalInstitutionId,
    organizationName,
    organizationInstitution,
    organizationInvoiceStatus,
  }: {
    institutionId?: string
    organizationName: string
    organizationInstitution: string
    organizationInvoiceStatus: OrganizationInvoiceStatus
  }
) => {
  const institutionCol = collection(repository.firestore, 'institution')

  const institutionId = optionalInstitutionId
    ? optionalInstitutionId
    : (
        await addDocWithError(
          institutionCol,
          {
            institutionName: organizationInstitution,
            updatedAt: serverTimestamp(),
          },
          'CreateOrganization-AddInstitutionError'
        )
      ).id

  const colRef = getColRef(repository.firestore)
  const organizationRef = await addDocWithError(
    colRef,
    {
      organizationName,
      organizationInstitution,
      organizationInvoiceStatus,
      organizationState: OrganizationState.active,
      institutionId: institutionId,
      updatedAt: serverTimestamp(),
    },
    'CreateOrganizationError'
  )
  return organizationRef.id
}

export const getOrganizationUsers = (
  repository: FirebaseRepository,
  {
    organizationId,
    userType,
  }: {
    organizationId: string
    userType: 'organization_admin' | 'organization_instructor'
  }
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const userColRef = collection(orgDocRef, userType)

  return collectionSnapshots(userColRef).asyncMap(async (snapshot) => {
    const userIds = snapshot.docs.map((doc) => doc.id)
    const appUsers = await fetchAppUsers(repository, { userIds })
    return appUsers
  })
}

export const updateOrganization = async (
  repository: FirebaseRepository,
  organizationId: string,
  {
    organizationName,
    organizationInstitution,
  }: {
    organizationName: string
    organizationInstitution: string
  }
) => {
  const colRef = getColRef(repository.firestore)
  const docRef = doc(colRef, organizationId)
  return updateDocWithError(
    docRef,
    {
      organizationName,
      organizationInstitution,
      updatedAt: serverTimestamp(),
    },
    'UpdateOrganizationError'
  )
}

export const updateOrganizationState = async (
  repository: FirebaseRepository,
  organizationId: string,
  organizationState: OrganizationState
) => {
  const colRef = getColRef(repository.firestore)
  const docRef = doc(colRef, organizationId)
  return updateDocWithError(
    docRef,
    {
      organizationState,
      updatedAt: serverTimestamp(),
    },
    'UpdateOrganizationStateError'
  )
}

export const addOrganizationalAdminToOrganization = async (
  repository: FirebaseRepository,
  {
    organizationId,
    userId,
  }: {
    organizationId: string
    userId: string
  }
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const userColRef = collection(orgDocRef, 'organization_admin')
  // add doc at user id
  const docRef = doc(userColRef, userId)
  setDocWithError(
    docRef,
    {
      updatedAt: serverTimestamp(),
      userId,
      organizationId,
    },
    {
      errorName: 'AddOrganizationalAdminToOrganizationError',
    }
  )
}

export const addInstructorToOrganization = async (
  repository: FirebaseRepository,
  {
    organizationId,
    userId,
  }: {
    organizationId: string
    userId: string
  }
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const userColRef = collection(orgDocRef, 'organization_instructor')
  // add doc at user id
  const docRef = doc(userColRef, userId)
  setDocWithError(
    docRef,
    {
      updatedAt: serverTimestamp(),
      userId,
      organizationId,
    },
    {
      errorName: 'AddInstructorToOrganizationError',
    }
  )
}

export const removeOrganizationalAdminFromOrganization = async (
  repository: FirebaseRepository,
  {
    organizationId,
    userId,
  }: {
    organizationId: string
    userId: string
  }
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const userColRef = collection(orgDocRef, 'organization_admin')
  const docRef = doc(userColRef, userId)
  return deleteDocWithError(
    docRef,
    'RemoveOrganizationalAdminFromOrganizationError'
  )
}

export const removeInstructorFromOrganization = async (
  repository: FirebaseRepository,
  {
    organizationId,
    userId,
  }: {
    organizationId: string
    userId: string
  }
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const userColRef = collection(orgDocRef, 'organization_instructor')
  const docRef = doc(userColRef, userId)
  return deleteDocWithError(docRef, 'RemoveInstructorFromOrganizationError')
}

export const fetchOrganizationsWithAdminAndInstructorIds = async (
  repository: FirebaseRepository
) => {
  // get all orgs
  const orgs = (
    await getDocsWithError(
      getColRef(repository.firestore),
      'FetchOrganizationsWithAdminAndInstructorIdsError'
    )
  ).docs

  const allOrgAdminsPromise = (async () => {
    return (
      await getDocsWithError(
        collectionGroup(repository.firestore, 'organization_admin'),
        'FetchOrganizationAdminsError'
      )
    ).docs.map((d) => d.data())
  })()

  const allOrgInstructorsPromise = (async () => {
    return (
      await getDocsWithError(
        collectionGroup(repository.firestore, 'organization_instructor'),
        'FetchOrganizationInstructorsError'
      )
    ).docs.map((d) => d.data())
  })()

  const [allOrgAdmins, allOrgInstructors] = await Promise.all([
    allOrgAdminsPromise,
    allOrgInstructorsPromise,
  ])

  const orgsWithInstructsAndAdmins = orgs.map((org) => ({
    ...org.data(),
    id: org.id,
    admins: new Set() as Set<string>,
    instructors: new Set() as Set<string>,
  }))

  // convert above arr to object with id as key
  const orgsById = orgsWithInstructsAndAdmins.reduce(
    (acc, org) => {
      acc[org.id] = org
      return acc
    },
    {} as Record<string, (typeof orgsWithInstructsAndAdmins)[0]>
  )

  // iterate over all org admins and add them to the appropriate org
  allOrgAdmins.forEach((admin) => {
    if (!('organizationId' in admin) || !(admin.organizationId in orgsById))
      return
    const org = orgsById[admin.organizationId]
    org.admins.add(admin.userId)
  })

  // iterate over all org instructors and add them to the appropriate org
  allOrgInstructors.forEach((instructor) => {
    if (
      !('organizationId' in instructor) ||
      !(instructor.organizationId in orgsById)
    )
      return
    const org = orgsById[instructor.organizationId]
    org.instructors.add(instructor.userId)
  })

  // convert sets to arrays and return data
  return Object.values(orgsById).map((org) => ({
    ...org,
    admins: Array.from(org.admins),
    instructors: Array.from(org.instructors),
  }))
}

export const userIsOrganizationAdmin = (
  repository: FirebaseRepository,
  userId: string
) => {
  const orgsQuery = query(
    collectionGroup(repository.firestore, 'organization_admin'),
    where('userId', '==', userId)
  )

  return collectionSnapshots(orgsQuery).asyncMap(async (snapshot) => {
    return snapshot.docs.length > 0
  })
}

export const userIsOrganizationInstructor = (
  repository: FirebaseRepository,
  userId: string
) => {
  const orgsQuery = query(
    collectionGroup(repository.firestore, 'organization_instructor'),
    where('userId', '==', userId)
  )

  return collectionSnapshots(orgsQuery).asyncMap(async (snapshot) => {
    return snapshot.docs.length > 0
  })
}

export const getOrganizationsWhereUserIsAdmin = (
  repository: FirebaseRepository,
  userId: string
) => {
  const orgsQuery = query(
    collectionGroup(repository.firestore, 'organization_admin'),
    where('userId', '==', userId)
  )
  return collectionSnapshots(orgsQuery).asyncMap(async (snapshot) => {
    const orgs = await Promise.all(
      snapshot.docs.map(async (doc) => {
        const parentDoc = await getDocWithError(
          doc.ref.parent.parent!.withConverter(converter),
          'FetchOrganizationError'
        )
        return convertDocumentSnapshotToModel(
          repository,
          parentDoc,
          Organization
        )
      })
    )
    return orgs
  })
}

export const getOrganizationsWhereUserIsInstructor = (
  repository: FirebaseRepository,
  userId: string
) => {
  const orgsQuery = query(
    collectionGroup(repository.firestore, 'organization_instructor'),
    where('userId', '==', userId)
  )
  return collectionSnapshots(orgsQuery).asyncMap(async (snapshot) => {
    const orgs = await Promise.all(
      snapshot.docs.map(async (doc) => {
        const parentDoc = await getDocWithError(
          doc.ref.parent.parent!.withConverter(converter),
          'FetchOrganizationError'
        )
        return convertDocumentSnapshotToModel(
          repository,
          parentDoc,
          Organization
        )
      })
    )
    return orgs
  })
}

export const getInstructorIdsForOrganization = (
  repository: FirebaseRepository,
  organizationId: string
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const userColRef = collection(orgDocRef, 'organization_instructor')
  return collectionSnapshots(userColRef).asyncMap(async (snapshot) => {
    return snapshot.docs.map((doc) => doc.id)
  })
}
