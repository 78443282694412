import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
} from 'firebase/firestore'
import {
  collection,
  doc,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreUserProfileRoomToken } from './schema'
import { schema } from './schema'
import { unsupportedConverter } from '../shared/noops'
import { convertDocumentSnapshotToModel } from '../../firestore-mobx/stream'
import { UserProfileRoomToken } from '../../models/UserProfileRoomToken'
import { getDocWithError } from '../../firestore-mobx/fetch'

const converter: FirestoreDataConverter<FirestoreUserProfileRoomToken> = {
  toFirestore: unsupportedConverter,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export const getColRef = (
  firestore: Firestore,
  params: { userId: string }
): CollectionReference<FirestoreUserProfileRoomToken, DocumentData> => {
  return collection(
    firestore,
    'user_profile',
    params.userId,
    'room_token'
  ).withConverter(converter)
}

export const getDocRef = (
  firestore: Firestore,
  params: {
    userId: string
    roomId: string
  }
): DocumentReference<FirestoreUserProfileRoomToken, DocumentData> => {
  const colRef = getColRef(firestore, params)

  return doc(colRef, params.roomId)
}

export const fetchUserProfileRoomToken = async (
  repository: FirebaseRepository,
  params: {
    userId: string
    roomId: string
  }
) => {
  const docRef = getDocRef(repository.firestore, params)

  const snapshot = await getDocWithError(
    docRef,
    'FetchUserProfileRoomTokenError'
  )

  return convertDocumentSnapshotToModel(
    repository,
    snapshot,
    UserProfileRoomToken
  )
}
