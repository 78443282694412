import {
  and,
  arrayUnion,
  collection,
  doc,
  documentId,
  getCountFromServer,
  query,
  serverTimestamp,
  where,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type CollectionReference,
  type DocumentData,
  type DocumentReference,
  type PartialWithFieldValue,
  arrayRemove,
  type Transaction,
} from 'firebase/firestore'
import { empty, schema, writeSchema } from './schema'
import type { FirestoreSlideDeck } from './schema'
import type { ObservableModel } from '../../firestore-mobx/model'
import {
  ObservableModelCollection,
  ObservableModelDocument,
} from '../../firestore-mobx/model'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SlideDeck, SlideDeckState } from '../../models/SlideDeck'
import {
  convertDocumentSnapshotToModel,
  modelItemStream,
  modelListStream,
} from '../../firestore-mobx/stream'
import type { z } from 'zod'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { safeDeleteStorageObject } from '../../util/safeDeleteStorageObject'
import { SlideDeckType } from '../../types'
import type { SetDocWithErrorsArgsMandatoryOptions } from '../../firestore-mobx/fetch'
import {
  addDocWithError,
  getDocsWithError,
  getDocWithError,
  setDocWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'

export interface SlideDeckObservableModel
  extends ObservableModel<FirestoreSlideDeck> {}

export interface SlideDeckObservableModelCollection
  extends ObservableModelCollection<SlideDeck, FirestoreSlideDeck> {}

const converter: FirestoreDataConverter<FirestoreSlideDeck> = {
  toFirestore: (data: PartialWithFieldValue<FirestoreSlideDeck>) => {
    writeSchema.partial().parse(data)
    // loop over data and set all undefined values to deleteField()
    // this is necessary because Firestore does not allow undefined values
    // and will throw an error if they are present
    // for (const key in data) {
    //   const typedKey = key as keyof typeof data
    //   if (data[typedKey] === undefined) data[typedKey] = deleteField()
    // }
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export const fetchFeaturedSlideDecks = async (
  repository: FirebaseRepository
) => {
  const colRef = getColRef(repository.firestore)
  const q = query(colRef, where('slideDeckFeatured', '==', true))
  const docs = await getDocsWithError(q, 'FetchFeaturedSlideDecksError')

  return docs.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideDeck)
  })
}

const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreSlideDeck> => {
  return collection(firestore, 'slide_deck').withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  slideDeckId: string
): DocumentReference<FirestoreSlideDeck, DocumentData> => {
  return doc(getColRef(firestore), slideDeckId)
}

export const buildSlideDeckObservableModelDocument = (
  repository: FirebaseRepository,
  slideDeckId: string
) => {
  const ref = !slideDeckId
    ? undefined
    : getDocRef(repository.firestore, slideDeckId)

  return new ObservableModelDocument({
    ref,
    repository,
    model: SlideDeck,
    empty: empty,
  })
}

export const buildSlideDeckObservableModelCollection = (
  repository: FirebaseRepository,
  params?: {
    slideDeckIds: string[]
  }
): SlideDeckObservableModelCollection => {
  const collection = new ObservableModelCollection({
    repository,
    model: SlideDeck,
    empty: empty,
  })

  if (params) configureSlideDeckObservableModelCollection(collection, params)

  return collection
}

export const configureSlideDeckObservableModelCollection = (
  collection: SlideDeckObservableModelCollection,
  params: {
    slideDeckIds: string[]
  }
) => {
  if (params.slideDeckIds.length === 0) {
    collection.attachTo(undefined)
    collection.query = undefined
  } else {
    collection.attachTo(getColRef(collection.repository.firestore))
    collection.query = (ref) => {
      return query(ref, where(documentId(), 'in', params.slideDeckIds))
    }
  }
}

export const getSlideDeck = (
  repository: FirebaseRepository,
  { slideDeckId }: { slideDeckId: string }
) => {
  const ref = getColRef(repository.firestore)
  const docRef = doc(ref, slideDeckId)

  return modelItemStream(repository, docRef, SlideDeck)
}

export const fetchSlideDeck = async (
  repository: FirebaseRepository,
  { slideDeckId }: { slideDeckId: string }
) => {
  const docRef = getDocRef(repository.firestore, slideDeckId)

  const doc = await getDocWithError(docRef, 'FetchSlideDeckError')

  if (!doc.exists()) throw new Error(`Slide deck ${slideDeckId} not found`)

  return convertDocumentSnapshotToModel(repository, doc, SlideDeck)
}

export const fetchSlideDecks = async (repository: FirebaseRepository) => {
  const docRef = getColRef(repository.firestore)

  const docs = await getDocsWithError(docRef, 'FetchSlideDecksError')

  return docs.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideDeck)
  })
}

export const getSlideDecksForCatalog = (
  repository: FirebaseRepository,
  { catalogId }: { catalogId: string }
) => {
  const ref = getColRef(repository.firestore)

  const catalogIdsPredicate = where('catalogIds', 'array-contains', catalogId)
  const statePredicate = where('slideDeckState', '>=', SlideDeckState.published)

  const q = query(ref, and(catalogIdsPredicate, statePredicate))

  return modelListStream(repository, q, SlideDeck)
}

export const fetchSlideDeckCountForCatalog = async (
  repository: FirebaseRepository,
  { catalogId }: { catalogId: string }
) => {
  const ref = getColRef(repository.firestore)

  const catalogIdsPredicate = where('catalogIds', 'array-contains', catalogId)
  const statePredicate = where('slideDeckState', '>=', SlideDeckState.published)

  const q = query(ref, and(catalogIdsPredicate, statePredicate))

  const snapshot = await getCountFromServer(q)

  return snapshot.data().count
}

/**
 * Get List of SlideDecks from Firestore
 * query on slideDeckState > 0 to avoid
 * soft deleted and initializing slide decks
 */
export const getSlideDecks = (repository: FirebaseRepository) => {
  const ref = getColRef(repository.firestore)
  const q = query(ref, where('slideDeckState', '>=', SlideDeckState.draft))
  return modelListStream(repository, q, SlideDeck)
}

export const getDemoSlideDecks = (repository: FirebaseRepository) => {
  const ref = getColRef(repository.firestore)
  const q = query(ref, and(where('slideDeckType', '==', SlideDeckType.demo)))
  return modelListStream(repository, q, SlideDeck)
}

/**
 * Create a new slide deck in Firestore
 */
export const createSlideDeck = async (
  repository: FirebaseRepository
): Promise<string> => {
  const colRef = getColRef(repository.firestore)
  const ref = await addDocWithError(
    colRef,
    {
      catalogIds: [],
      slideDeckState: SlideDeckState.draft,
      slideDeckFree: false,
      slideDeckGoogleTemplateURL: '',
      slideDeckImageURL: '',
      slideDeckName: '',
      slideDeckPrice: 1,
      slideDeckVersion: '0.0.0', // initial value
      slideDeckTeaser: '',
      slideDeckType: SlideDeckType.original,
      slideDeckTypeId: '',
      slideDeckFeatured: false,
      slideDeckDisciplines: [],
      slideDeckIndustries: [],
      slideDeckKeyConcepts: [],
      slideDeckLearningObjectives: [],
      slideDeckTags: [],

      updatedAt: serverTimestamp(),
    },
    'CreateSlideDeckError'
  )

  return ref.id
}

/// add a [Catalog] id entry to a [SlideDeck]
/// slide decks with a catalog id should always have published state
export const addCatalogToSlideDeck = async (
  repository: FirebaseRepository,
  {
    catalogId,
    slideDeckId,
    transaction,
  }: { catalogId: string; slideDeckId: string; transaction?: Transaction }
) => {
  const set = (...args: SetDocWithErrorsArgsMandatoryOptions) =>
    transaction ? transaction.set(...args) : setDocWithError(...args)
  const docRef = getDocRef(repository.firestore, slideDeckId)
  return set(
    docRef,
    {
      catalogIds: arrayUnion(catalogId),
      slideDeckState: SlideDeckState.published,
    },
    { merge: true, errorName: 'AddCatalogToSlideDeckError' }
  )
}

export const removeCatalogFromSlideDeck = async (
  repository: FirebaseRepository,
  {
    catalogId,
    slideDeckId,
    transaction,
  }: { catalogId: string; slideDeckId: string; transaction?: Transaction }
) => {
  const set = (...args: SetDocWithErrorsArgsMandatoryOptions) =>
    transaction ? transaction.set(...args) : setDocWithError(...args)
  const docRef = getDocRef(repository.firestore, slideDeckId)
  return await set(
    docRef,
    { catalogIds: arrayRemove(catalogId) },
    { merge: true, errorName: 'RemoveCatalogFromSlideDeckError' }
  )
}

export const removeCatalogFromSlideDeckAndUnFeature = async (
  repository: FirebaseRepository,
  {
    catalogId,
    slideDeckId,
    transaction,
  }: { catalogId: string; slideDeckId: string; transaction?: Transaction }
) => {
  const set = (...args: SetDocWithErrorsArgsMandatoryOptions) =>
    transaction ? transaction.set(...args) : setDocWithError(...args)
  const docRef = getDocRef(repository.firestore, slideDeckId)
  return await set(
    docRef,
    {
      catalogIds: arrayRemove(catalogId),
      slideDeckFeatured: false,
      updatedAt: serverTimestamp(),
    },
    { merge: true, errorName: 'RemoveCatalogFromSlideDeckAndUnFeatureError' }
  )
}

export type ExperienceDetailsForUpload = {
  slideDeckDescription: string
  slideDeckDisciplines: string[]
  slideDeckFeatured: boolean
  slideDeckType: SlideDeckType
  slideDeckGoogleTemplateURL: string
  slideDeckIndustries: string[]
  slideDeckKeyConcepts: string[]
  slideDeckLearningObjectives: string[]
  slideDeckTags: string[]
  slideDeckName: string
  slideDeckPrice: number
  slideDeckTeaser: string
  slideDeckVersion: string
  slideDeckImageURL: string
}

export const saveSlideDeckForm = async (
  repository: FirebaseRepository,
  slideDeckId: string,
  payload: Partial<ExperienceDetailsForUpload>
) => {
  const data = {
    ...payload,
    slideDeckFree: payload.slideDeckPrice === 0,
    updatedAt: serverTimestamp(),
  }

  const docRef = getDocRef(repository.firestore, slideDeckId)

  return setDocWithError(docRef, data, {
    merge: true,
    errorName: 'SaveSlideDeckError',
  })
}

export const getSlideDecksWithTypeId = (
  repository: FirebaseRepository,
  { slideDeckTypeId }: { slideDeckTypeId: string }
) => {
  const ref = getColRef(repository.firestore)
  const q = query(ref, where('slideDeckTypeId', '==', slideDeckTypeId))
  return modelListStream(repository, q, SlideDeck)
}

/**
 * Creates a shallow copy of a [SlideDeck] with state set to -1
 * to fire the deep copy trigger. The promise resolves when the deep copy
 * finishes and sets the state to 0 (draft)
 */
export const deepCopySlideDeck = async (
  repository: FirebaseRepository,
  {
    slideDeck,
    newVersionName,
  }: { slideDeck: SlideDeck; newVersionName: string }
) => {
  // alias for slideDeckData (less typing)
  const d = slideDeck.data
  const writeData: z.infer<typeof writeSchema> = {
    catalogIds: [],
    slideDeckDescription: d.slideDeckDescription,
    slideDeckFeatured: false,
    slideDeckFree: d.slideDeckFree,
    slideDeckName: d.slideDeckName,
    slideDeckPrice: d.slideDeckPrice,
    slideDeckTeaser: d.slideDeckTeaser,
    slideDeckParentId: slideDeck.id,
    slideDeckType: d.slideDeckType,
    slideDeckTypeId: d.slideDeckTypeId,
    // different from dart
    // industries/disciplines/learning objectives were not copied
    // but that was probably an oversight
    slideDeckDisciplines: d.slideDeckDisciplines,
    slideDeckIndustries: d.slideDeckIndustries,
    slideDeckKeyConcepts: d.slideDeckKeyConcepts,
    slideDeckLearningObjectives: d.slideDeckLearningObjectives,
    slideDeckTags: d.slideDeckTags,
    // slide deck state uninitialized invokes the deep copy trigger
    slideDeckState: SlideDeckState.uninitialized,
    slideDeckVersion: newVersionName,
    updatedAt: serverTimestamp(),
  }
  const { id } = await addDocWithError(
    getColRef(repository.firestore),
    writeData,
    'DeepCopySlideDeckError'
  )
  const docRef = getDocRef(repository.firestore, id)

  // stream the newly created slide deck and return when
  // the slide deck state moves from uninitialized to draft
  return await modelItemStream(repository, docRef, SlideDeck).firstWhere(
    (d) => d.slideDeckState === SlideDeckState.draft
  )
}

// Upload a slide deck image
export const uploadSlideDeckImage = async (
  repository: FirebaseRepository,
  { slideDeckId, file }: { slideDeckId: string; file: File }
) => {
  const mimeType = file.type
  if (!mimeType.startsWith('image/')) {
    throw new Error('Invalid image type')
  }

  const storageRef = ref(repository.storage, `slide_deck/images/${slideDeckId}`)
  await uploadBytes(storageRef, file, {
    contentType: mimeType,
  })

  // get the download url and strip the token param
  const urlWithoutToken = (await getDownloadURL(storageRef)).replaceAll(
    /&token=[a-z0-9-]{36}/g,
    ''
  )

  const docRef = getDocRef(repository.firestore, slideDeckId)

  setDocWithError(
    docRef,
    { slideDeckImageURL: urlWithoutToken },
    { merge: true, errorName: 'UploadSlideDeckImageError' }
  )
  return urlWithoutToken
}

export const deleteSlideDeckImage = async (
  repository: FirebaseRepository,
  slideDeckId: string
) => {
  const storageRef = ref(repository.storage, `slide_deck/images/${slideDeckId}`)
  await safeDeleteStorageObject(storageRef)

  const docRef = getDocRef(repository.firestore, slideDeckId)
  return setDocWithError(
    docRef,
    { slideDeckImageURL: '' },
    { merge: true, errorName: 'DeleteSlideDeckImageError' }
  )
}

export const updateSlideDeckFeatured = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    slideDeckFeatured,
    transaction,
  }: {
    slideDeckId: string
    slideDeckFeatured: boolean
    transaction?: Transaction
  }
) => {
  const docRef = getDocRef(repository.firestore, slideDeckId)
  const set = (...args: SetDocWithErrorsArgsMandatoryOptions) =>
    transaction ? transaction.set(...args) : setDocWithError(...args)
  return set(
    docRef,
    { slideDeckFeatured },
    { merge: true, errorName: 'UpdateSlideDeckFeaturedError' }
  )
}

export const hideSlideDeck = async (
  repository: FirebaseRepository,
  { slideDeckId }: { slideDeckId: string }
) => {
  const docRef = getDocRef(repository.firestore, slideDeckId)
  return setDocWithError(
    docRef,
    {
      slideDeckState: SlideDeckState.hidden,
      updatedAt: serverTimestamp(),
    },
    { merge: true, errorName: 'HideSlideDeckError' }
  )
}

export const showSlideDeck = async (
  repository: FirebaseRepository,
  { slideDeckId }: { slideDeckId: string }
) => {
  const docRef = getDocRef(repository.firestore, slideDeckId)
  return setDocWithError(
    docRef,
    {
      slideDeckState: SlideDeckState.published,
      slideDeckFeatured: false,
      updatedAt: serverTimestamp(),
    },
    { merge: true, errorName: 'ShowSlideDeckError' }
  )
}

export const deleteSlideDeck = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
  }: {
    slideDeckId: string
  }
) => {
  const docRef = getDocRef(repository.firestore, slideDeckId)
  return setDocWithError(
    docRef,
    {
      slideDeckState: SlideDeckState.deleted,
      slideDeckFeatured: false,
      updatedAt: serverTimestamp(),
    },
    { merge: true, errorName: 'DeleteSlideDeckError' }
  )
}

export const touchSlideDeck = async (
  repository: FirebaseRepository,
  { slideDeckId }: { slideDeckId: string }
) => {
  const docRef = getDocRef(repository.firestore, slideDeckId)
  return setDocWithError(
    docRef,
    {
      updatedAt: serverTimestamp(),
    },
    { merge: true, errorName: 'TouchSlideDeckError' }
  )
}

export const forkSlideDeck = async (
  repository: FirebaseRepository,
  { slideDeckId }: { slideDeckId: string }
) => {
  const docRef = getDocRef(repository.firestore, slideDeckId)
  await updateDocWithError(
    docRef,
    {
      slideDeckTypeId: slideDeckId,
      updatedAt: serverTimestamp(),
    },
    'ForkSlideDeckError'
  )
}
