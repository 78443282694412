import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeFirestoreTimestamp,
  nullSafeOptionalBoolean,
  nullSafeOptionalString,
  nullSafeStringArray,
} from '../shared/schema'

export enum SectionState {
  notStarted = 0,
  inProgress = 1,
  completed = 2,
}

// this is the dart definition
// required String className,
// required String id,
// required int instructorUserId,
// required String sectionName,
// required SectionState sectionState, default: SectionState.notStarted
// required bool shareable, default: false
// required List<String> userIds, default: []
// required List<PublicUser> users,
// bool? invoiced, default: false
// int? updatedAt,
export const schema = z.object({
  className: z.string(),
  instructorUserId: z.string(),
  invoiced: nullSafeOptionalBoolean(false),
  sectionName: z.string(),
  sectionState: z.preprocess((val) => {
    return val ?? SectionState.notStarted
  }, z.nativeEnum(SectionState)),
  shareable: nullSafeOptionalBoolean(false),
  userIds: nullSafeStringArray([]),
  organizationId: nullSafeOptionalString(''),
  updatedAt: nullSafeFirestoreTimestamp(),
})

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    instructorUserId: '',
    className: '',
    sectionName: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export type FirestoreSectionInferredType = z.infer<typeof schema>

export interface FirestoreSection extends FirestoreSectionInferredType {}
