import { computed, makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  roomStateSetGroupLeaders,
  updateRoomState,
  type FirestoreRoomState,
} from '../firestore/RoomState'
import type { FirebaseRepository } from './FirebaseRepository'
import { DateTime } from 'luxon'
import { serverTimestamp } from 'firebase/firestore'

export class RoomState extends ObservableModelWithDecorators<FirestoreRoomState> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreRoomState>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  @computed
  get users() {
    return this.repository.userStore.getUsers(this.data.userIds)
  }

  @computed
  get groupLeaders() {
    return this.repository.userStore.getUsers(this.groupLeaderUserIds)
  }

  @computed
  get groupLeader() {
    return this.repository.userStore.getUser(this.groupLeaderUserId)
  }

  @computed
  get groupLeaderUserIds() {
    return this.data.groupLeaderUserIds
  }

  @computed
  get roomStateName(): string {
    if (!this.hasData) return ''
    if (this.data.roomStateName) return this.data.roomStateName
    return this.groupLeaders[0]?.fullName ?? ''
  }

  @computed
  get slideDeckId() {
    return this.hasData ? this.data.slideDeckId : ''
  }

  @computed
  get userIds() {
    return this.data.userIds
  }

  @computed
  get currentUserIsHidden(): boolean {
    return !!(
      this.data.hiddenUserIds &&
      this.data.hiddenUserIds.includes(this.repository.uid)
    )
  }

  @computed
  get userCount() {
    return this.userIds.length
  }

  @computed
  get groupLeaderUserId() {
    return this.groupLeaderUserIds[0]
  }

  @computed
  get isActive() {
    const activeSlide = this.data.activeSlide
    if (activeSlide === undefined) return false
    if (activeSlide === null) return false
    return activeSlide >= 0
  }

  @computed
  get isScheduled() {
    return (this.data.scheduledAt || null) !== null
  }

  @computed
  get isCompleted() {
    const activeSlide = this.data.activeSlide
    const activeSlideChangedAt = this.data.activeSlideChangedAt || null
    // if activeSlide is null, then the room state is not live
    if (activeSlideChangedAt === null) return false
    let delayCompleteByMinutes = 15

    if (activeSlide === 0) {
      delayCompleteByMinutes = 60
    }
    const lastChangeInMillis = activeSlideChangedAt.getTime()
    const delayAmount = 60000 * delayCompleteByMinutes
    const delayed = lastChangeInMillis + delayAmount
    if (delayed !== null && delayed < new Date().getTime()) {
      return true
    }
    return false
  }

  @computed
  get canStart() {
    const scheduledAt = this.data.scheduledAt
    if (!scheduledAt) return false
    return (
      this.repository.currentMinute >
      DateTime.fromJSDate(scheduledAt).minus({ minutes: 10 })
    )
  }

  get scheduledAtDate() {
    return this.data.scheduledAt
      ? DateTime.fromJSDate(this.data.scheduledAt)
      : undefined
  }

  get scheduledAtDateAsIsoShort() {
    if (!this.scheduledAtDate) return ''
    const meetingScheduledAtFormatted =
      this.scheduledAtDate.toISODate() +
      'T' +
      this.scheduledAtDate.toFormat('HH:mm')
    return meetingScheduledAtFormatted
  }

  getRoomStateStatus(expiresAt: Date | undefined, slideCount: number) {
    if (!this.data.activeSlide && this.data.activeSlide !== 0) {
      if (expiresAt && expiresAt.getTime() < new Date().getTime()) {
        return RoomStateStatus.expired
      } else if (this.data.scheduledAt) {
        return RoomStateStatus.scheduled
      } else {
        return RoomStateStatus.mustSchedule
      }
    } else {
      if (this.lastSlideChangeOver20MinutesAgo) {
        if (this.data.activeSlide === slideCount - 1) {
          return RoomStateStatus.completed
        } else {
          return RoomStateStatus.abandoned
        }
      } else {
        return RoomStateStatus.live
      }
    }
  }

  @computed
  get lastSlideChangeOver20MinutesAgo() {
    const activeSlideChangedAt = this.data.activeSlideChangedAt
    if (!activeSlideChangedAt) return false

    const delayCompleteByMinutes = 20

    const time20MinsAgo =
      activeSlideChangedAt.getTime() + 1000 * 60 * delayCompleteByMinutes

    return time20MinsAgo < this.repository.currentMinute.toMillis()
  }

  startRoomIfNotStarted = async () => {
    const activeSlide = this.data.activeSlide
    if (activeSlide === null || activeSlide === undefined) {
      // if we are not a group leader, make ourselves a a group leader
      if (!this.data.groupLeaderUserIds.includes(this.repository.uid)) {
        await roomStateSetGroupLeaders(this.repository.firestore, this.id, [
          this.repository.uid,
        ])
      }
      // we are in a room that is not started, start it and become the leader
      updateRoomState(this.repository.firestore, this.id, {
        activeSlide: 0,
        activeSlideChangedAt: serverTimestamp(),
        roomStartedAt: serverTimestamp(),
      })
    }
  }
}

/// define the room state status enum
export enum RoomStateStatus {
  /// live
  live = 0,

  /// completed
  completed = 1,

  /// abandoned
  abandoned = 2,

  /// scheduled
  scheduled = 3,

  /// mustSchedule
  mustSchedule = 4,

  /// expired
  expired = 5,
}
