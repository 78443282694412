import { action, computed, makeObservable } from 'mobx'
import type { MeetingCubit } from '../../MeetingCubit'
import { SlideType } from '../../../firestore/Slide/types'
import type { SlideModel } from '../../../models/SlideModel'

// TODO: change into a model
export class Slide {
  id: string
  name: string
  index: number
  duration: number
  slideVideoDuration?: number
  slideVideoURL: string | null = null
  slideImageURL: string | null = null
  description: string
  type: SlideType
  order: number
  meeting: MeetingCubit

  constructor(meeting: MeetingCubit, model: SlideModel, index: number) {
    this.meeting = meeting
    const data = model.data
    this.id = model.id
    this.index = index
    this.name = data.slideName
    this.duration = data.slideDuration
    this.description = data.slideDescription
    this.type = data.slideType
    this.order = data.slideOrder || 0
    this.slideVideoDuration = data.slideVideoDuration
    this.slideVideoURL = data.slideVideoURL || null
    this.slideImageURL = data.slideImageURL || null

    makeObservable(this, {
      duration: true,
      titleIndex: computed,
      updateDuration: action,
    })

    this.afterConstructor()
  }

  // This is used un subclasses to not need to super() the constructor
  afterConstructor() {}

  get durationInMinutes() {
    let duration = 0
    if (this.slideVideoDuration) {
      duration = Math.ceil(this.slideVideoDuration / 60)
    } else {
      duration = Math.ceil(this.duration / 60)
    }
    return duration
  }

  updateDuration(duration: number) {
    this.duration = duration
  }

  get titleIndex(): number {
    return 0
  }

  get hasImage(): boolean {
    return !!this.slideImageURL
  }

  get isVideoSlide() {
    return (
      this.type === SlideType.video ||
      (this.type === SlideType.processingData && !this.hasImage)
    )
  }
}
