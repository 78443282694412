import type { FirebaseOptions } from 'firebase/app'
import { initializeApp } from 'firebase/app'
import type { User } from 'firebase/auth'
import {
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
  type Auth,
} from 'firebase/auth'
import {
  connectFirestoreEmulator,
  initializeFirestore,
  type Firestore,
} from 'firebase/firestore'
import {
  connectFunctionsEmulator,
  getFunctions,
  type Functions,
} from 'firebase/functions'
import {
  connectStorageEmulator,
  getDownloadURL,
  getStorage,
  ref,
  type FirebaseStorage,
} from 'firebase/storage'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { UnsubscribeManager } from '../util/UnsubscribeManager'
import { BreakoutUser } from './BreakoutUser'
import { UserStore } from '../stores/UserStore'
import { DateTime } from 'luxon'
import { browserLocalPersistence, setPersistence } from 'firebase/auth'
import type { SlideDeckMaterial } from './SlideDeckMaterial'
import {
  getAnalytics,
  logEvent,
  setUserId,
  type Analytics,
} from 'firebase/analytics'
import { decodeJwt } from 'jose'
import * as Sentry from '@sentry/core'
import { EventEmitter } from '../util/EventEmitter'
import type { StreamSubscriptionActions } from 'tricklejs/dist/stream_subscription'
import { getSettingsFeatureFlags } from '../firestore/SettingsFeatureFlags'
import { SettingsFeatureFlags } from './SettingsFeatureFlags'
import { getSettingsFeatureFlagsBeta } from '../firestore/SettingsFeatureFlagsBeta'

export type BuildOptions = {
  firebase: FirebaseOptions
  useLocalPersistence?: boolean
  disableAnalytics?: boolean
  inBeta?: boolean
}

// TODO: move to stores
// Single class housing all Firebase services
export class FirebaseRepository {
  firestore: Firestore
  auth: Auth
  storage: FirebaseStorage
  functions: Functions
  analytics?: Analytics
  initialized = false
  inBeta = false

  currentUser: User | null = null
  breakoutUser: BreakoutUser | null = null

  _authToken: string | null = null
  _authTokenRefreshTimeout: NodeJS.Timeout | undefined = undefined
  _featureFlagStream: StreamSubscriptionActions | undefined = undefined
  _featureFlags: SettingsFeatureFlags

  // with observables, relying on time is a bit tricky
  // so we have a separate observable for the current minute
  // which is updated every minute
  // A minute, because we don't need to be more granular than that
  currentMinute: DateTime = DateTime.now()
  _currentMinuteInterval: NodeJS.Timeout | null = null

  userStore: UserStore = new UserStore(this)

  unsubscribers = new UnsubscribeManager()
  events = new EventEmitter()

  static build(options: BuildOptions) {
    const app = initializeApp(options.firebase)
    const auth = getAuth(app)
    const databaseId = localStorage.getItem('firestore-databaseId')
    let firestore: Firestore
    if (databaseId) {
      firestore = initializeFirestore(
        app,
        { ignoreUndefinedProperties: true },
        databaseId
      )
    } else {
      firestore = initializeFirestore(app, { ignoreUndefinedProperties: true })
    }
    const storage = getStorage(app)
    const functions = getFunctions(app)
    const analytics = options.disableAnalytics ? undefined : getAnalytics(app)

    if (options.useLocalPersistence) {
      setPersistence(auth, browserLocalPersistence)
    }

    const repository = new FirebaseRepository(
      firestore,
      auth,
      storage,
      functions,
      analytics
    )

    if (options.inBeta) repository.inBeta = true

    return repository
  }

  constructor(
    firestore: Firestore,
    auth: Auth,
    storage: FirebaseStorage,
    functions: Functions,
    analytics?: Analytics
  ) {
    this.firestore = firestore
    this.auth = auth
    this.storage = storage
    this.functions = functions
    this.analytics = analytics

    this.breakoutUser = null

    this._featureFlags = SettingsFeatureFlags.empty(this)

    makeObservable(this, {
      // observables
      _authToken: observable,
      currentUser: observable,
      initialized: observable,
      currentMinute: observable,

      breakoutUser: observable,
      // actions
      update: action,
      finalizeInitialization: action,
      updateCurrentMinute: action,

      // computed
      uid: computed,
      isAuthenticated: computed,
      authToken: computed,
    })
  }

  initialize() {
    this.startMinuteTicker()
    this.unsubscribers.add(
      onAuthStateChanged(this.auth, (user) => {
        this.update({ user })
        if (!this.initialized) this.finalizeInitialization()
      })
    )

    // fetch/refresh the auth token when currentUser changes
    this.unsubscribers.add(
      reaction(
        () => this.currentUser,
        async () => {
          this.refreshAuthToken()
        },
        { fireImmediately: true }
      )
    )

    return this
  }

  onLogout(callback: () => void) {
    this.events.addListener('logout', callback)
  }

  offLogout(callback: () => void) {
    this.events.removeListener('logout', callback)
  }

  get featureFlags() {
    this.startFeatureFlagStreamIfNotRunning()
    return this._featureFlags
  }

  startFeatureFlagStreamIfNotRunning() {
    if (!this.uid) return
    if (this._featureFlagStream) return

    const stream = this.inBeta
      ? getSettingsFeatureFlagsBeta(this)
      : getSettingsFeatureFlags(this)
    this._featureFlagStream = stream.listen((model) => {
      this._featureFlags.replaceModel(model)
    })
  }

  logEvent(name: string, params?: Record<string, unknown>) {
    if (this.analytics === undefined) return
    logEvent(this.analytics, name, params)
  }

  setAnalyticsUserId(userId: string | null) {
    if (this.analytics === undefined) return
    setUserId(this.analytics, userId)
  }

  startMinuteTicker() {
    this._currentMinuteInterval = setInterval(() => {
      this.updateCurrentMinute()
    }, 60 * 1000)
  }

  updateCurrentMinute() {
    this.currentMinute = DateTime.now()
  }

  finalizeInitialization() {
    this.initialized = true
  }

  dispose() {
    this._currentMinuteInterval && clearInterval(this._currentMinuteInterval)
    this.unsubscribers.dispose()
  }

  connectToEmulator(host?: string) {
    const emulatorHost = host || 'localhost'

    // skip if we're already using the emulator in the browser
    // hot reload can cause this to be called multiple times
    if (this.auth.emulatorConfig) return

    connectAuthEmulator(this.auth, `http://${emulatorHost}:9099`, {
      disableWarnings: true,
    })
    connectFirestoreEmulator(this.firestore, emulatorHost, 8080)
    connectStorageEmulator(this.storage, emulatorHost, 9199)
    connectFunctionsEmulator(this.functions, emulatorHost, 5001)
  }

  update(data: { user: User | null }) {
    if (data.user !== undefined) {
      this.currentUser = data.user

      if (data.user) {
        this.setAnalyticsUserId(data.user.uid)

        this.breakoutUser = new BreakoutUser(this, data.user.uid)
        this.breakoutUser.initialize()
        Sentry.setUser({ id: data.user.uid })
      } else {
        this.logout()
        this.setAnalyticsUserId(null)
        this.breakoutUser = new BreakoutUser(this, '')
        Sentry.setUser(null)
      }
    }
  }

  get authToken() {
    // check if the token is expired as a fail safe in case the token is not refreshed
    if (this._authToken) {
      const { exp } = decodeJwt(this._authToken)
      // should only occur if the 10 minute refresh failed
      const nowMinusFiveMinutes = DateTime.now()
        .minus({ minutes: 5 })
        .toSeconds()
      if (exp && exp < nowMinusFiveMinutes) this.refreshAuthToken(true)
    }
    return this._authToken
  }

  get uid() {
    return this.breakoutUser?.uid || ''
  }

  get isAuthenticated() {
    return this.currentUser !== null
  }

  logout() {
    // we're logging out, dispose of the user
    this.breakoutUser?.dispose()
    // clear the user store and cancel subscription
    this.userStore.clear()

    this.events.emit('logout')

    if (this._featureFlagStream) {
      this._featureFlagStream.cancel()
      this._featureFlagStream = undefined
    }
  }

  getPdfMaterialDownloadUrl = async (
    slideDeckId: string,
    material: SlideDeckMaterial
  ) => {
    const pdfRef = ref(
      this.storage,
      `slide_deck/${slideDeckId}/material/${material.id}.pdf`
    )
    const pdfUrl = await getDownloadURL(pdfRef)

    return pdfUrl
  }

  refreshAuthToken = async (forceRefresh = false) => {
    clearTimeout(this._authTokenRefreshTimeout)
    // if we're not authenticated, clear the token
    if (!this.currentUser) {
      this._authToken = null
      return
    }

    try {
      // eslint-disable-next-line no-console
      if (process.env.NODE_ENV !== 'test') console.log('Fetching new AuthToken')

      const token = await this.currentUser.getIdToken(forceRefresh)

      // Decode the token to get the expiration time
      const { exp } = decodeJwt(token)
      const nowSeconds = DateTime.now().toSeconds()

      // Set the token if it's not expired
      if (exp && exp > nowSeconds) this._authToken = token

      // force refresh 10 mins before expiration
      const refreshAt = Math.max(exp ? exp - nowSeconds - 600 : 0, 0)
      this._authTokenRefreshTimeout = setTimeout(
        () => this.refreshAuthToken(true),
        refreshAt * 1000
      )
    } catch (e) {
      // if we fail to get the token, try again in 10 seconds
      this._authTokenRefreshTimeout = setTimeout(
        () => this.refreshAuthToken(true),
        10 * 1000
      )
    }
  }
}
