import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import { Organization } from '../models/Organization'
import {
  addInstructorToOrganization,
  addOrganizationalAdminToOrganization,
  updateOrganizationState,
  getOrganization,
  getOrganizationUsers,
  removeInstructorFromOrganization,
  removeOrganizationalAdminFromOrganization,
  updateOrganization,
} from '../firestore/Organization'
import { type AppUser } from '../stores/AppUser'
import { fetchAllAppUsersByRole } from '../firestore/PublicUser'
import { type OrganizationState, UserProfileRole } from '../types'

export class AdminOrganizationCubit extends Cubit {
  repository: FirebaseRepository
  organizationId: string
  organization: Organization

  private _adminsForOrganization = observable.array<AppUser>()
  private _instructorsForOrganization = observable.array<AppUser>()

  @observable
  private didLoadAdminsForOrganization = false

  @observable
  private didLoadInstructorsForOrganization = false

  @observable
  private didFetchAllInstructors = false

  @observable
  private didLoadAllInstructors = false

  private allInstructors = observable.array<AppUser>()

  constructor(repository: FirebaseRepository, organizationId: string) {
    super()
    makeObservable(this)
    this.repository = repository
    this.organization = Organization.empty(repository)
    this.organizationId = organizationId
  }

  initialize(): void {
    this.addStream(
      getOrganization(this.repository, { organizationId: this.organizationId }),
      (org) => {
        this.organization.replaceModel(org)
      }
    )
    this.addStream(
      getOrganizationUsers(this.repository, {
        organizationId: this.organizationId,
        userType: 'organization_admin',
      }),
      (admins) => {
        runInAction(() => {
          this.didLoadAdminsForOrganization = true
          this._adminsForOrganization.replace(admins)
        })
      }
    )
    this.addStream(
      getOrganizationUsers(this.repository, {
        organizationId: this.organizationId,
        userType: 'organization_instructor',
      }),
      (instructors) => {
        runInAction(() => {
          this.didLoadInstructorsForOrganization = true
          this._instructorsForOrganization.replace(instructors)
        })
      }
    )
  }

  updateOrganization = async (params: {
    organizationName: string
    organizationInstitution: string
  }): Promise<void> => {
    await updateOrganization(this.repository, this.organizationId, params)
  }

  updateOrganizationState = async (state: OrganizationState): Promise<void> => {
    await updateOrganizationState(this.repository, this.organizationId, state)
  }

  @computed
  get addableInstructors(): { loading: boolean; users: AppUser[] } {
    if (!this.didFetchAllInstructors) this.fetchInstructors()
    return {
      loading: !this.didLoadAllInstructors,
      // Filter out instructors that are already associated with the organization
      users: this.allInstructors.filter((instructor) => {
        return !this._instructorsForOrganization.find(
          (u) => u.uid === instructor.uid
        )
      }),
    }
  }

  @computed
  get addableOrganizationalAdmins(): { loading: boolean; users: AppUser[] } {
    if (!this.didFetchAllInstructors) this.fetchInstructors()
    return {
      loading: !this.didLoadInstructorsForOrganization,
      // Filter out OrganizationalAdmins that are already associated with the organization
      users: this.allInstructors.filter((instructor) => {
        return !this._adminsForOrganization.find(
          (u) => u.uid === instructor.uid
        )
      }),
    }
  }

  @computed
  get adminsForOrganization() {
    return {
      loading: !this.didLoadAdminsForOrganization,
      users: this._adminsForOrganization,
    }
  }

  @computed
  get instructorsForOrganization() {
    return {
      loading: !this.didLoadInstructorsForOrganization,
      users: this._instructorsForOrganization,
    }
  }

  @action
  fetchInstructors() {
    this.didFetchAllInstructors = true
    fetchAllAppUsersByRole(this.repository, UserProfileRole.instructor).then(
      (instructors) => {
        this.didLoadAllInstructors = true
        this.allInstructors.replace(instructors)
      }
    )
  }

  addInstructor = async (userId: string): Promise<void> => {
    addInstructorToOrganization(this.repository, {
      userId,
      organizationId: this.organizationId,
    })
  }

  addOrganizationalAdmin = async (userId: string): Promise<void> => {
    addOrganizationalAdminToOrganization(this.repository, {
      userId,
      organizationId: this.organizationId,
    })
  }

  removeInstructor = async (instructor: AppUser): Promise<void> => {
    removeInstructorFromOrganization(this.repository, {
      userId: instructor.uid,
      organizationId: this.organizationId,
    })
  }

  removeOrganizationalAdmin = async (admin: AppUser): Promise<void> => {
    removeOrganizationalAdminFromOrganization(this.repository, {
      userId: admin.uid,
      organizationId: this.organizationId,
    })
  }
}
