import { computed, makeObservable, observable, runInAction } from 'mobx'

import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { StaticModelCollection } from '../types'
import { Cubit } from './core'
import {
  getInstructorIdsForOrganization,
  getOrganizationsWhereUserIsAdmin,
  getOrganizationsWhereUserIsInstructor,
} from '../firestore/Organization'
import { Organization } from '../models/Organization'
import { createOrganizationInvitation } from '../firestore/Invitation'

export class InstructorOrganizationsCubit extends Cubit {
  repository: FirebaseRepository
  adminOrgs: StaticModelCollection<Organization>
  instructorOrgs: StaticModelCollection<Organization>

  @observable instructorIdsByOrgId = observable.map<string, string[]>()

  @observable private instructorIdsLoaded = false

  constructor(repository: FirebaseRepository) {
    super()
    makeObservable(this)
    this.repository = repository
    this.adminOrgs = Organization.emptyCollection(repository)
    this.instructorOrgs = Organization.emptyCollection(repository)
  }

  initialize(): void {
    if (!this.repository.currentUser) return
    this.addStream(
      getOrganizationsWhereUserIsAdmin(
        this.repository,
        this.repository.currentUser?.uid
      ),
      (orgs) => {
        this.adminOrgs.replaceModels(orgs)
        if (!orgs.length) this.checkInstructorIdsLoaded()
        orgs.forEach((org) => {
          const key = `${org.id}-organization-instructors`
          if (this.hasStream(key)) return
          this.addStream(
            getInstructorIdsForOrganization(this.repository, org.id),
            (instructorIds) => {
              this.instructorIdsByOrgId.set(org.id, instructorIds)
              this.checkInstructorIdsLoaded()
            },
            {
              name: key,
            }
          )
        })
      }
    )

    this.addStream(
      getOrganizationsWhereUserIsInstructor(
        this.repository,
        this.repository.currentUser?.uid
      ),
      (orgs) => {
        this.instructorOrgs.replaceModels(orgs)
      }
    )
  }

  createOrganizationInvitation = async (
    params: Parameters<typeof createOrganizationInvitation>[1]
  ) => {
    return await createOrganizationInvitation(this.repository, params)
  }

  // i wanted to do this in a reaction but it wouldn't fire when i updated the map, cba on why
  private checkInstructorIdsLoaded() {
    if (this.instructorIdsLoaded) return
    const allOrgsHaveInstructorIds = this.adminOrgs.models.every((org) =>
      this.instructorIdsByOrgId.has(org.id)
    )
    if (allOrgsHaveInstructorIds) {
      runInAction(() => {
        this.instructorIdsLoaded = true
      })
    }
  }

  @computed
  get loaded() {
    return (
      this.instructorIdsLoaded &&
      this.adminOrgs.isLoaded &&
      this.instructorOrgs.isLoaded
    )
  }
}
