import { action, computed, makeObservable, observable } from 'mobx'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import {
  OrganizationState,
  type OrganizationInvoiceStatus,
  type StaticModelCollection,
} from '../types'
import { Cubit } from './core'
import { Organization } from '../models/Organization'
import { createOrganization, getOrganizations } from '../firestore/Organization'

export class AdminOrganizationsCubit extends Cubit {
  repository: FirebaseRepository
  private _organizations: StaticModelCollection<Organization>

  @observable
  showArchived = false

  constructor(repository: FirebaseRepository) {
    super()
    makeObservable(this)
    this.repository = repository
    this._organizations = Organization.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(getOrganizations(this.repository), (orgs) => {
      this._organizations.replaceModels(orgs)
    })
  }

  @action
  toggleShowArchived() {
    this.showArchived = !this.showArchived
  }

  createOrganization(params: {
    organizationName: string
    organizationInstitution: string
    organizationInvoiceStatus: OrganizationInvoiceStatus
  }): Promise<string> {
    return createOrganization(this.repository, params)
  }

  @computed
  get organizations() {
    if (this.showArchived) return this._organizations.models

    return this._organizations.models.filter(
      (org) => org.data.organizationState === OrganizationState.active
    )
  }
}
