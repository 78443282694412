import {
  collection,
  doc,
  query,
  serverTimestamp,
  where,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirestoreInvitation } from './schema'
import { schema } from './schema'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { DateTime } from 'luxon'
import { UserProfileRole } from '../UserProfile/types'
import { InvitationType } from '../../types'
import {
  convertDocumentSnapshotToModel,
  modelListStream,
} from '../../firestore-mobx/stream'
import { Invitation } from '../../models/Invitation'
import {
  addDocWithError,
  getDocsWithError,
  getDocWithError,
} from '../../firestore-mobx/fetch'

const converter: FirestoreDataConverter<FirestoreInvitation> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export async function createStudentInvitation(
  repository: FirebaseRepository,
  {
    sectionId,
    type,
  }: {
    sectionId: string
    type?: InvitationType
  }
) {
  const firestore = repository.firestore
  const invitationsRef = collection(firestore, 'invitation').withConverter(
    converter
  )

  const data: Partial<FirestoreInvitation> = {
    sectionId: sectionId,
    userId: repository.uid,
    updatedAt: new Date(),
    oneTime: false,
  }

  if (type === InvitationType.multiUse) {
    data.expiresAt = DateTime.now().plus({ month: 3 }).toJSDate()
  } else {
    data.oneTime = true
  }

  return addDocWithError(invitationsRef, data, 'CreateStudentInvitationError')
}

export async function createTaInvitation(
  repository: FirebaseRepository,
  {
    instructorUserId,
  }: {
    instructorUserId?: string
  }
) {
  const firestore = repository.firestore
  const invitationsRef = collection(firestore, 'invitation').withConverter(
    converter
  )

  const data: Partial<FirestoreInvitation> = {
    role: UserProfileRole.ta,
    expiresAt: DateTime.now().plus({ month: 1 }).toJSDate(),
    oneTime: true,
    userId: instructorUserId || repository.uid,
    updatedAt: new Date(),
  }

  return addDocWithError(invitationsRef, data, 'CreateTaInvitationError')
}

export async function createSectionInvitation(repository: FirebaseRepository) {
  const firestore = repository.firestore
  const invitationsRef = collection(firestore, 'invitation').withConverter(
    converter
  )

  const data: Partial<FirestoreInvitation> = {
    role: UserProfileRole.ta,
    expiresAt: DateTime.now().plus({ month: 1 }).toJSDate(),
    oneTime: true,
    userId: repository.uid,
    updatedAt: new Date(),
  }

  return addDocWithError(invitationsRef, data, 'CreateSectionInvitationError')
}

/// create role invitation with catalog
export const createInvitationInstructorWithCatalog = (
  repository: FirebaseRepository,
  { catalogId, oneTime = true }: { catalogId: string; oneTime?: boolean }
) => {
  const firestore = repository.firestore
  const invitationsRef = collection(firestore, 'invitation').withConverter(
    converter
  )

  const data: Partial<FirestoreInvitation> = {
    catalogId: catalogId,
    oneTime: true,
    role: UserProfileRole.instructor,
    userId: repository.uid,
    updatedAt: new Date(),
  }
  if (!oneTime) {
    delete data.oneTime
    data.expiresAt = DateTime.now().plus({ month: 1 }).toJSDate()
  }

  return addDocWithError(
    invitationsRef,
    data,
    'CreateInstructorInvitationError'
  )
}

export const createDemoInvitation = (
  repository: FirebaseRepository,
  {
    roomId,
    firstName,
    lastName,
    emailAddress,
  }: {
    roomId: string
    firstName: string
    lastName: string
    emailAddress: string
  }
) => {
  const firestore = repository.firestore
  const invitationsRef = collection(firestore, 'invitation').withConverter(
    converter
  )

  const data: Partial<FirestoreInvitation> = {
    oneTime: false,
    userId: repository.uid,
    updatedAt: new Date(),
    isDemo: true,
    demoArguments: {
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      roomId: roomId,
    },
    expiresAt: DateTime.now().plus({ month: 1 }).toJSDate(),
  }

  return addDocWithError(invitationsRef, data, 'CreateDemoInvitationError')
}

export const fetchDemoInvitations = async (
  repository: FirebaseRepository,
  {
    roomId,
  }: {
    roomId: string
  }
) => {
  const firestore = repository.firestore
  const invitationsRef = collection(firestore, 'invitation').withConverter(
    converter
  )

  const predicate = where('demoArguments.roomId', '==', roomId)
  const q = query(invitationsRef, predicate)

  const docs = await getDocsWithError(q, 'FetchDemoInvitationsError')

  return docs.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, Invitation)
  })
}

export const getDemoInvitations = (
  repository: FirebaseRepository,
  {
    roomId,
  }: {
    roomId: string
  }
) => {
  const firestore = repository.firestore
  const invitationsRef = collection(firestore, 'invitation').withConverter(
    converter
  )

  const predicate = where('demoArguments.roomId', '==', roomId)
  const q = query(invitationsRef, predicate)

  return modelListStream(repository, q, Invitation)
}

export const isSectionInvitation = async (
  repository: FirebaseRepository,
  { invitationId }: { invitationId: string }
) => {
  const colRef = collection(repository.firestore, 'invitation').withConverter(
    converter
  )
  const docRef = doc(colRef, invitationId)
  const invitation = await getDocWithError(docRef, 'FetchInvitationError')
  const exists = invitation.exists()
  if (!exists) return false
  if (invitation.data().sectionId) return true
  return false
}

export const createOrganizationInvitation = async (
  repository: FirebaseRepository,
  {
    organizationId,
    isAdminInvitation,
  }: {
    organizationId: string
    isAdminInvitation: boolean
  }
) => {
  const colRef = collection(repository.firestore, 'invitation').withConverter(
    converter
  )

  // todo: this role is redundant now as this will only be used by instructors
  // and role elevation will not be supported
  const invitationData = {
    role: UserProfileRole.instructor,
    expiresAt: DateTime.now().plus({ month: 1 }).toJSDate(),
    oneTime: true,
    userId: repository.uid,
    updatedAt: serverTimestamp(),
    organizationId,
    organizationAdmin: isAdminInvitation,
  }
  const ref = await addDocWithError(
    colRef,
    invitationData,
    'CreateOrganizationInvitationError'
  )
  return ref.id
}
