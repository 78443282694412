export * from './firestore'
import type { FirestoreRoomStateAnswer } from '../firestore/RoomStateAnswer/schema'
import type { FirestoreRoomStateSummary } from '../firestore/RoomStateSummary/schema'
import type { PublicUser } from '../models/PublicUser'
import type { FirestoreRoomMessage } from '../firestore/RoomMessage/schema'
import type { FirestorePublicUser } from '../firestore/PublicUser/schema'
import {
  FirestoreRoomMessageType,
  FirestoreRoomMessageStatus,
} from '../firestore/RoomMessage/schema'
import type { MobxDocument } from '../firestore-mobx'
import type { StaticModelCollection } from '../firestore-mobx/model'
import {
  SlideDeckMaterialType,
  SlideDeckMaterialWorkType,
} from '../firestore/SlideDeckMaterial/schema'
import type { MaterialRequiredFieldsForUpload } from '../firestore/SlideDeckMaterial'
import type { ExhibitFieldsForUpload } from '../firestore/SlideDeckExhibit'
import type { AuthorFieldsForUpload } from '../firestore/SlideDeckAuthor'
import type { QuestionFieldsForUpload } from '../firestore/SlideQuestion'
import type { SlideFieldsForUpload } from '../firestore/Slide'
import type { ExperienceDetailsForUpload } from '../firestore/SlideDeck'
import { SlideType } from '../firestore/Slide/types'
import { RoomStateVideoMethod } from '../firestore/RoomState/types'
import { UserProfileRole } from '../firestore/UserProfile/types'
import type { FirestoreTeachingPlan } from '../firestore/TeachingPlan/schema'
import type { TeachingPlanDetailsPayload } from '../firestore/TeachingPlan/types'
import { TeachingPlanUploadType } from '../firestore/TeachingPlan/types'
import { WrappedFirestoreError } from '../firestore-mobx/errors'
import { TeachingPlanStatus } from '../firestore/TeachingPlan/types'
import { PromotionType } from '../firestore/UserPromotion/types'
import {
  OrganizationInvoiceStatus,
  OrganizationState,
} from '../firestore/Organization/schema'

export type {
  FirestoreRoomStateAnswer,
  FirestoreRoomStateSummary,
  FirestoreRoomMessage,
  FirestorePublicUser,
  StaticModelCollection,
  MobxDocument,
  MaterialRequiredFieldsForUpload,
  ExhibitFieldsForUpload,
  AuthorFieldsForUpload,
  QuestionFieldsForUpload,
  SlideFieldsForUpload,
  ExperienceDetailsForUpload,
  FirestoreTeachingPlan,
  TeachingPlanDetailsPayload,
}

export {
  FirestoreRoomMessageType,
  FirestoreRoomMessageStatus,
  SlideDeckMaterialType,
  SlideDeckMaterialWorkType,
  SlideType,
  RoomStateVideoMethod,
  UserProfileRole,
  TeachingPlanUploadType,
  WrappedFirestoreError,
  TeachingPlanStatus,
  PromotionType,
  OrganizationInvoiceStatus,
  OrganizationState,
}

export enum InstructorSectionView {
  cases,
  students,
}

export enum AssignmentState {
  pending = 0,
  completed = 1,
}

// deprecated
export enum SectionState {
  notStarted = 0,
  inProgress = 1,
  completed = 2,
}

export enum InvitationConsumeResult {
  errorAlreadyConsumed = 1,
  errorCreatedByYou = 2,
  errorInvitationNotFound = 3,
  errorInvitationExpired = 4,
  success = 5,
  errorPromotionAlreadyConsumed = 6,
}

export enum LibraryObjectState {
  // you are in a room and it is running
  live,
  // you are in a room and we are near the scheduled time
  readyToStart,
  // you have been assigned to a slideDeck but not joined a room
  // but have not paid for the assignment
  invited,
  // you have been assigned to a slideDeck but not joined a room
  // and have paid for the assignment
  enrolled,
  // you are groupLeader and have not scheduled a room
  mustSchedule,
  // you are groupLeader and have scheduled a room
  scheduled,
  // you are in a room that is not scheduled and you are not groupLeader
  pending,
  // you are in a room that is completed
  completed,
  // your assignment has expired and the room is incomplete
  expired,
}

export enum AssignmentType {
  studentLed = 0,
  instructorLed = 1,
}

export enum MaterialWorkType {
  neither = 0,
  preWork = 1,
  postWork = 2,
}

export enum MaterialType {
  url = 0,
  pdf = 1,
  mp3 = 2,
  webm = 3,
  featuredLarge = 4,
  jpg = 5,
}

export enum RubricType {
  blooms = 0,
  passFail = 1,
}

// All possible message types.
export enum ChatMessageType {
  Audio = 'audio',
  Custom = 'custom',
  File = 'file',
  Image = 'image',
  System = 'system',
  Text = 'text',
  Unsupported = 'unsupported',
  Video = 'video',
}

// Base interface for a message
export interface ChatMessage {
  authorId: string
  author: PublicUser
  id: string
  text: string
  type: FirestoreRoomMessageType
  createdAt?: Date
  metadata?: Record<string, unknown>
  remoteId?: string
  repliedMessage?: ChatMessage
  roomId?: string
  showStatus?: boolean
  status?: FirestoreRoomMessageStatus
  updatedAt?: Date
  firstFromUser: boolean
  showAvatar: boolean
  timestamp: Date
  isCurrentUserMessage: boolean
}

export type MeetingSidebarTab = 'peers' | 'exhibits' | 'agenda' | 'chat'

export enum InvitationType {
  oneTime = 0,
  multiUse = 1,
}

/**
 * wraps firestore document data and adds an id field
 */
export type WithID<T> = T & { id: string }

export enum BroadcastState {
  uninitialized = 'uninitialized',
  initialized = 'initialized',
  playing = 'playing',
  paused = 'paused',
  stopped = 'stopped',
}

export type TranscriptMessage = {
  transcript: string
  final: boolean
  identity: string
  id: number
}

export type FirestoreInvitation = {
  userId: string
  sectionId: string
  expiresAt: { seconds: number; nanoseconds: number }
  promotionId?: string
  promotionArguments?: {
    collisionCode: string
  }
}

export enum SessionMuting {
  none = 'none',
  all = 'all',
  groupLeader = 'groupLeader',
}

export enum SlideDeckState {
  deleted = -2,
  uninitialized = -1,
  draft = 0,
  published = 1,
  hidden = 2,
}

export enum SlideDeckType {
  original = 0,
  dialog = 1,
  simulation = 2,
  negotiation = 3,
  demo = 4,
}

export enum SlideDeckReferenceType {
  alternativeTo = 0,
  basedOn = 1,
}

export enum BloomScore {
  none = 0,
  remember = 1,
  understand = 2,
  apply = 3,
  analyze = 4,
  evaluate = 5,
  create = 6,
}

export enum PassFailScore {
  fail = 0,
  pass = 1,
}

export class DocumentDoesNotExistError extends Error {
  constructor() {
    super('Document does not exist')
  }
}
