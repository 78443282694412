import z from 'zod'
import { nullSafeBoolean, nullSafeNumber } from '../shared/schema'
import { SessionMuting, RoomStateVideoMethod } from '../../types'

const sessionMutingSchema = z.nativeEnum(SessionMuting)
const videoMethodSchema = z.nativeEnum(RoomStateVideoMethod)

export const schema = z.object({
  allowEmailUpdate: nullSafeBoolean(false),
  animationMethod: nullSafeBoolean(true),
  automatedRandomGrouping: nullSafeBoolean(true),
  generateGoogleSlides: nullSafeBoolean(false),
  generateSessionSummary: nullSafeBoolean(true),
  rubricTestDesiredStdDeviation: nullSafeNumber(1.5),
  rubricTestMeanEpsilon: nullSafeNumber(0.9),
  sendInstructorEmails: nullSafeBoolean(false),
  sessionMuting: z.preprocess(
    (val) => val ?? SessionMuting.none,
    sessionMutingSchema
  ),
  sessionResultsGroupBalanceCoefficient: nullSafeNumber(0.5),
  sessionResultsShowAllConversationTooltips: nullSafeBoolean(true),
  sessionResultsUseNew: nullSafeBoolean(false),
  studentLibraryUseNew: nullSafeBoolean(false),
  useStrictPublishRequirements: nullSafeBoolean(false),
  videoMethod: z.preprocess(
    (val) => val ?? RoomStateVideoMethod.broadcast,
    videoMethodSchema
  ),
  showOrganizationsUI: nullSafeBoolean(false),
})

export const empty = Object.freeze(schema.parse({}))

export type FirestoreSettingsFeatureFlags = z.infer<typeof schema>
